import React from "react";
import QRCode from "qrcode.react";

function QRCodeComponent({ url }) {
  return (
    <div className="qrcode_react">
      <QRCode
        value={url}
        size={60} // حجم الرمز
        bgColor="#ffffff" // لون الخلفية
        fgColor="#000000" // لون الرمز
        level="H" // مستوى التصحيح (L, M, Q, H)
        renderAs="svg" // صيغة الإخراج (svg, canvas)
        includeMargin={false} // إضافة هامش
        imageSettings={{
          excavate: false, // إزالة الجزء المتداخل
        }}
      />
    </div>
  );
}

export default QRCodeComponent;
