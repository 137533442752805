import React, { useRef, useState } from "react";
import amman from "../../img/amman.webp";
import pdf from "../../img/pdf.webp";
import "./home.css";
import html2pdf from "html2pdf.js";
import QRCodeComponent from "./QRCodeComponent";

const Home = () => {
  const formRef = useRef(null);
  const currentURL = window.location.href;
  const [isDisplay, setDisplay] = useState(false);
  const [isDisplayImg, setDisplayImg] = useState(true);

  const btnconfirminputForm = () => {
    document.body.style.overflow = "hidden";

    setDisplay(true);

    const formNode = formRef.current;

    if (formNode) {
      const pdfOptions = {
        margin: 1,
        filename: "occ_module_new1-T283681978028368378bc8.pdf",
        image: { type: "png", quality: 0.6 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      html2pdf()
        .from(formNode)
        .set(pdfOptions)
        .output("blob")
        .then(function (blob) {
          const url = URL.createObjectURL(blob);
          window.location.href = url;
          setDisplayImg(false);
        });
    }
  };

  return (
    <>
      <div className="fixed" style={{ display: isDisplay ? "" : "none" }}>
        <span className="loader"></span>
      </div>

      {isDisplayImg && (
        <div
          ref={formRef}
          className="qrcode"
          style={{ display: isDisplay ? "" : "none" }}
        >
          <img src={pdf} alt="pdf" style={{ width: "100%" }} />
          <QRCodeComponent url={currentURL} />
        </div>
      )}

      {!isDisplay && (
        <center>
          <table border="0" cellPadding="0" cellSpacing="">
            <tbody>
              <tr>
                <td height="10"></td>
              </tr>
              <tr>
                <td height="60" className="bbigbrowntxt">
                  التحقق من صحة الوثيقة الالكترونية الصادرة من أمانة عمّان
                  الكبرى
                </td>
              </tr>
              <tr>
                <td align="center">
                  <img src={amman} alt="amman" border="0" />
                </td>
              </tr>
              <tr>
                <td height="10"></td>
              </tr>
              <tr>
                <td height="10"></td>
              </tr>
              <tr>
                <td height="10"></td>
              </tr>
              <tr>
                <td align="center">
                  <span id="lblmsg">
                    <span className="acceptfont">رقم المرجع : </span>
                    <span className="greenfont">00032025001000165</span>
                  </span>
                </td>
              </tr>
              <tr>
                <td height="10"></td>
              </tr>

              <tr align="center">
                <td>
                  <div>
                    <table
                      cellPadding="5"
                      border="0"
                      id="GridView1"
                      style={{
                        borderWidth: "0px",
                        borderStyle: "None",
                        fontSize: "20px",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tbody>
                        <tr
                          className="sitefont"
                          style={{ color: "white", backgroundColor: "#4E80A1" }}
                        >
                          <th scope="col">البيانات</th>
                          <th scope="col">الحقول</th>
                        </tr>
                        <tr
                          align="right"
                          valign="middle"
                          style={{
                            color: "#4A3C8C",
                            backgroundColor: "#D7D7D9",
                          }}
                        >
                          <td>147 :</td>
                          <td>رقم الطلب</td>
                        </tr>
                        <tr
                          align="right"
                          valign="middle"
                          style={{
                            color: "#4A3C8C",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          <td>2022 :</td>
                          <td>السنة</td>
                        </tr>
                        <tr
                          align="right"
                          valign="middle"
                          style={{
                            color: "#4A3C8C",
                            backgroundColor: "#D7D7D9",
                          }}
                        >
                          <td>ابو نصير :</td>
                          <td>المنطقه</td>
                        </tr>
                        <tr
                          align="right"
                          valign="middle"
                          style={{
                            color: "#4A3C8C",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          <td>١١١ - أبو نصير :</td>
                          <td>أسم القرية</td>
                        </tr>
                        <tr
                          align="right"
                          valign="middle"
                          style={{
                            color: "#4A3C8C",
                            backgroundColor: "#D7D7D9",
                          }}
                        >
                          <td>١٠ - أصهى الفقير :</td>
                          <td>الحوض</td>
                        </tr>
                        <tr
                          align="right"
                          valign="middle"
                          style={{
                            color: "#4A3C8C",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          <td>755 :</td>
                          <td>رقم القطعة</td>
                        </tr>
                        <tr
                          align="right"
                          valign="middle"
                          style={{
                            color: "#4A3C8C",
                            backgroundColor: "#D7D7D9",
                          }}
                        >
                          <td>اذن اشغال :</td>
                          <td>نوع الطلب</td>
                        </tr>
                        <tr
                          align="right"
                          valign="middle"
                          style={{
                            color: "#4A3C8C",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          <td>نايل خالد يوسف العدوان :</td>
                          <td>أسم مقدم الطلب</td>
                        </tr>
                        <tr
                          align="right"
                          valign="middle"
                          style={{
                            color: "#4A3C8C",
                            backgroundColor: "#D7D7D9",
                          }}
                        >
                          <td>نايل خالد يوسف العدوان :</td>
                          <td>أسم مالك العقار</td>
                        </tr>
                        <tr
                          align="right"
                          valign="middle"
                          style={{
                            color: "#4A3C8C",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          <td>مدفوع :</td>
                          <td>حالة الطلب</td>
                        </tr>
                        <tr
                          align="right"
                          valign="middle"
                          style={{
                            color: "#4A3C8C",
                            backgroundColor: "#D7D7D9",
                          }}
                        >
                          <td>0 :</td>
                          <td>قيمة الرسوم</td>
                        </tr>
                        <tr
                          align="right"
                          valign="middle"
                          style={{
                            color: "#4A3C8C",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          <td>0 :</td>
                          <td>قيمة ضريبة المبيعات</td>
                        </tr>
                        <tr
                          align="right"
                          valign="middle"
                          style={{
                            color: "#4A3C8C",
                            backgroundColor: "#D7D7D9",
                          }}
                        >
                          <td>
                            <button
                              className="btnconfirminputForm"
                              onClick={btnconfirminputForm}
                            >
                              تحميل الملف
                            </button>
                          </td>
                          <td>الملف المراد تحميله</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
              <tr>
                <td height="10"></td>
              </tr>
              <tr>
                <td align="center"></td>
              </tr>
            </tbody>
          </table>
        </center>
      )}
    </>
  );
};

export default Home;
